
import { Vue, Component } from 'vue-property-decorator'
import { ProjectDeviceList, SoilHumidityDetail, DeviceInfo } from '@/types/ecologyMonitor'
import { PolygonPathDetail } from '@/types/common'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'

@Component
export default class SoilMonitor extends Vue {
  // 折叠面板当前展开项
  private activeName = 0
  // 当前地图展示项目
  private projectId = ''
  // 项目列表
  private projectDeviceList: ProjectDeviceList[] = []
  // 当前项目设备列表
  private deviceList = []
  private projectLocation: Array<PolygonPathDetail> = []
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []
  private onlineImg = require('@/assets/icon/soilHumidity/normal.svg')
  private onlineImgCursor = require('@/assets/icon/soilHumidity/normal-big.svg')
  private offImg = require('@/assets/icon/soilHumidity/offline.svg')
  private offImgCursor = require('@/assets/icon/soilHumidity/offline-big.svg')
  private warningImg = require('@/assets/icon/soilHumidity/warning.svg')
  private warningImgCursor = require('@/assets/icon/soilHumidity/warning-big.svg')

  private drawerData: SoilHumidityDetail | null = null
  private detailInfo: Array<{title: string; value: string; icon: string; status: string}> = []

  created () {
    this.getData()
  }

  destroyed () {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  // 左侧项目列表
  getData () {
    this.$axios.get(this.$apis.ecologyMonitor.selectProjectSoilByList).then(res => {
      this.projectDeviceList = res || []
      if (this.projectDeviceList.length > 0) {
        this.projectId = this.projectDeviceList[0].projectId
        this.getPloygon()
      } else {
        this.$nextTick(() => {
          this.initMap(116.4017, 39.9)
        })
      }
    })
  }

  // 切换项目
  changeProject () {
    const id = this.projectDeviceList[Number(this.activeName)].projectId
    if ((this.activeName || this.activeName === 0) && this.projectId !== id) {
      this.projectId = id
      this.getPloygon()
      this.drawerClose()
    }
  }

  // 获取项目围栏
  getPloygon () {
    this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
      projectId: this.projectId
    }).then((res) => {
      if (res) {
        this.projectLocation = res.locationList || []
        const lng = Number(res.longitude)
        const lat = Number(res.latitude)
        this.$nextTick(() => {
          this.initMap(lng, lat)
        })
      } else {
        this.$message.error('未获取到项目信息')
      }
    })
  }

  getDeviceList () {
    this.$axios.get(this.$apis.ecologyMonitor.selectSoilInfoByPage, {
      projectId: this.projectId
    }).then(res => {
      this.deviceList = res.soil.list || []
      this.drawMarker(this.deviceList)
    })
  }

  // 初始化地图
  initMap (lng: number, lat: number) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [lng, lat]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, this.projectLocation || [], null, this.drawerClose)
      this.getDeviceList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 地图打点
  drawMarker (list: Array<any>) {
    this.markers = []
    if (list && list.length) {
      // 循坏将设备marker添加到地图
      list.forEach((item: SoilHumidityDetail) => {
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.deviceStatus, item.runStatus, false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          })
          marker.detail = item
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 切换设备
  changeDevice (row: DeviceInfo) {
    if (this.map) {
      const e: any = {
        target: {}
      }
      if (row.longitude && row.latitude) {
        e.target = this.map.getAllOverlays('marker').find((item: any) => {
          return item.detail.deviceNumber === row.deviceNumber
        })
        this.map.setCenter([Number(row.longitude), Number(row.latitude)])
      } else {
        this.iconTab()
        e.target = {
          detail: row
        }
      }
      this.drawerOpen(e)
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    const status = {
      temperature: '',
      humidity: '',
      ec: ''
    }
    if ((this.drawerData === null || (this.drawerData && this.drawerData.deviceNumber !== e.target.detail.deviceNumber))) {
      const info = [
        {
          title: '温度(°C)',
          value: '-',
          icon: 'icon-icon_shidu',
          status: ''
        },
        {
          title: '湿度(%)',
          value: '-',
          icon: 'icon-icon_wendu',
          status: ''
        },
        {
          title: 'pH',
          value: '-',
          icon: 'icon-icon_PH',
          status: ''
        },
        {
          title: 'EC(mS/cm)',
          value: '-',
          icon: 'icon-icon_EC',
          status: ''
        },
        {
          title: '氮(mg/kg)',
          value: '-',
          icon: 'icon-icon_dan',
          status: ''
        },
        {
          title: '磷(mg/kg)',
          value: '-',
          icon: 'icon-icon_lin',
          status: ''
        },
        {
          title: '钾(mg/kg)',
          value: '-',
          icon: 'icon-icon_jia',
          status: ''
        },
        {
          title: 'ORP(mV)',
          value: '-',
          icon: 'icon-icon_ORP',
          status: ''
        }
      ]
      if (this.drawerData && this.drawerData.deviceNumber !== e.target.detail.deviceNumber) {
        this.drawerClose()
      }
      if (e.target.detail.deviceNumber) {
        this.drawerData = e.target.detail
        // 点击切换大图标
        const iconClick = this.getIcon(e.target.detail.deviceStatus, e.target.detail.runStatus, true)
        if (e.target.detail.longitude && e.target.detail.latitude) {
          e.target.setOffset(new AMap.Pixel(-22, -64))
          e.target.setIcon(iconClick)
        }
        // 温度
        info[0].value = e.target.detail.temperature || '--'
        switch (e.target.detail.temperatureStatus) {
          case '0':
            status.temperature = '正常'
            break
          case '1':
            status.temperature = '过热'
            break
          case '2':
            status.temperature = '冰冻'
            break
          default:
            status.temperature = '--'
            break
        }
        info[0].status = status.temperature
        // 湿度
        info[1].value = e.target.detail.humidity || '--'
        switch (e.target.detail.humidityStatus) {
          case '0':
            status.humidity = '正常'
            break
          case '1':
            status.humidity = '过涝'
            break
          case '2':
            status.humidity = '干旱'
            break
          case '3':
            status.humidity = '极度干旱'
            break
          default:
            status.humidity = '--'
            break
        }
        info[1].status = status.humidity
        // ph
        info[2].value = e.target.detail.ph || '--'
        info[2].status = '--'
        // ec
        info[3].value = (e.target.detail.conductivity) || '--'
        switch (e.target.detail.conductivityStatus) {
          case '1':
            status.ec = '正常'
            break
          case '2':
            status.ec = '偏高'
            break
          case '3':
            status.ec = '偏低'
            break
          default:
            status.ec = '--'
            break
        }
        info[3].status = status.ec
        // 氮
        info[4].value = (e.target.detail.nitrogen) || '--'
        info[4].status = this.getStatus(e.target.detail.nitrogenStatus)
        // 磷
        info[5].value = (e.target.detail.phosphorus) || '--'
        info[5].status = this.getStatus(e.target.detail.phosphorusStatus)
        // 钾
        info[6].value = (e.target.detail.potassium) || '--'
        info[6].status = this.getStatus(e.target.detail.potassiumStatus)
        // orp
        info[7].value = (e.target.detail.redox) || '--'
        info[7].status = this.getStatus(e.target.detail.redoxStatus)
        this.detailInfo = info
      }
    }
    this.drawer = true
  }

  getStatus (num: string) {
    const arr = ['正常', '偏高', '偏低']
    return num ? arr[Number(num)] : '--'
  }

  // 获取图片
  getIcon (deviceStatus: string, runStatus: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (deviceStatus === '1' ? (runStatus === '1' ? this.warningImg : this.onlineImg) : this.offImg)
    } else {
      return (deviceStatus === '1' ? (runStatus === '1' ? this.warningImgCursor : this.onlineImgCursor) : this.offImgCursor)
    }
  }

  // 抽屉关闭
  drawerClose () {
    console.log(this.drawer)
    if (this.drawer) {
      this.drawer = false
      // 清空抽屉
      this.iconTab()
      this.drawerData = null
    }
  }

  // 替换图标
  iconTab () {
    if (this.map && this.drawerData) {
      const deviceNumber = this.drawerData.deviceNumber || ''
      const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceNumber === deviceNumber)
      const icon = this.getIcon(this.drawerData.deviceStatus, this.drawerData.runStatus, false)
      const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
      if (this.drawerData.longitude && this.drawerData.latitude) {
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }
    }
  }
}
